a {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-contrast: #989aa2;
  --ion-color-contrast-rgb: 152, 154, 162;
  --ion-color-shade: #d7d8da;
  --ion-color-tint: #f5f6f9;
}

.sc-ion-back-button-ios {
  font-weight: 400;
}

.footer-ios ion-toolbar,
.md ion-toolbar {
  --border-width: 0 !important;
}
ion-footer.twb-footer {
  --background: var(--ion-color-light);

  ion-toolbar {
    --background: var(--ion-color-light);

    padding: 10px;
    padding-bottom: 20px !important;
    --border-width: 0 !important;
  }

  .footer-button {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    --border-radius: 12px;
    margin-bottom: 10px;
    button {
      border-radius: 12px;
    }
  }
}

// ###################################################################################

ion-button.blue_btn.md::ng-deep,
ion-button.blue_btn.ios::ng-deep {
  --border-radius: 27.5px !important;
  --box-shadow: 0 2px 11px 0 rgba(255, 255, 255, 0.5) !important;
  --background: linear-gradient(to right, #68b2f5, #2bcfda) !important;
  margin-bottom: 21px !important;
  --border: 0 !important;
  --font-size: 18px !important;
  --text-transform: uppercase !important;
  --outline: none !important;
}
ion-button.transparent_btn.md::ng-deep,
ion-button.transparent_btn.ios::ng-deep {
  --border-radius: 27.5px !important;
  --text-transform: uppercase !important;
  --outline: none !important;
  --color: #ffffff !important;
  --border: solid 1px #ffffff !important;
  --font-size: 18px !important;
  --text-transform: uppercase !important;
  margin-bottom: 25px !important;
  --outline: none !important;
}

// ###################################################################################

.intro-text {
  padding-left: 15px;
  padding-right: 15px;
  text-align: justify;
}

a.tc {
  color: white !important;
}

.label-stacked {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: var(--ion-color-primary) !important;
}

.validation-error-text {
  float: left;
  color: var(--ion-color-danger);
  font-size: 12px;
  width: 50%;
  text-align: left;
  width: 100%;
  padding-left: 17px;
}

.alert-card {
  a {
    color: var(--ion-color-primary-contrast);
    margin: 5px;
    &.btn {
      font-size: small;
    }
  }
}

.rules-card {
  background: rgba(220, 220, 220, 0.3);
  box-shadow: none;
  font-size: 14px;

  fa-layers.status-icon {
    margin-right: 20px;
    font-size: 18px;
    color: var(--ion-color-primary);
  }

  ion-card-header {
  }

  ion-card-content,
  .card-content-ios,
  .card-content-md {
    font-size: 14px;

    fa-icon {
      float: right;
      cursor: pointer;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    div.rules-ok,
    div.rules-nok {
      margin-bottom: 15px;

      img {
        width: 36px;
        float: left;
        margin-right: 10px;
      }

      ul {
        margin-top: 5px;
      }
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

// ###################################################################################

ion-menu {
  .submenu {
    padding-left: 30px;
  }

  .submenu .item-md.item-block .item-inner {
    padding-right: 30px;
    border-bottom: none;
  }

  .twb-avatar-cropper {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: var(--twb-image-radius);
  }

  .display-name {
    margin-bottom: 10px;
    text-align: center;
    margin-top: -18px;
    font-weight: bold;
    font-size: large;
    // color: white;
  }
}

.hidden {
  display: none;
}

.twb-avatar-cropper {
  height: auto;
  position: relative;
  overflow: hidden;
  border-radius: var(--twb-image-radius);
}

.twb-avatar-rounded {
  display: block;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;
  padding: 2px;
  line-height: 1.52857143;
  background-color: #e1e2e3;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-align: center;
  align-content: center;
  align-items: center;
  background-position-x: center;
  position: relative;
}

.settings-icon {
  background: #fff;
  position: absolute;
  bottom: 7px;
  right: 7px;
  border-radius: 50%;
  padding: 1px;
  font-size: 20px;
}

// .twb-avatar-rounded.thumb48 {
//   border: 2px solid #e1e2e3;
//   width: 48px;
//   height: 48px;
// }

// .twb-avatar-rounded.thumb64 {
//   border: 2px solid #e1e2e3;
//   width: 64px;
//   height: 64px;
// }

// .twb-avatar-rounded.thumb96 {
//   border: 2px solid #e1e2e3;
//   width: 96px;
//   height: 96px;
// }

// .twb-avatar-rounded.thumb128 {
//   border: 2px solid #e1e2e3;
//   width: 128px;
//   height: 128px;
// }

// .widget.panel,
// .widget .panel {
//   overflow: hidden;
// }

// .widget {
//   margin-bottom: 20px;
//   border: 0;
// }

// .panel {
//   margin-bottom: 21px;
//   background-color: #fff;
//   border: 1px solid transparent;
//   border-radius: 4px;
//   -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
//   box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
// }

// .panel-member .member-infos .circle-success {
//   background-color: #019b8a;
//   border: #04b71a 1px solid;
// }

// .circle-lg {
//   width: 11px;
//   height: 11px;
// }

// .circle-success {
//   background-color: #019b8a;
// }

// .circle {
//   display: inline-block;
//   width: 7px;
//   height: 7px;
//   border-radius: 500px;
//   margin: 0 0.5em;
//   background-color: #ddd;
//   vertical-align: baseline;
//   border: 2px solid transparent;
// }

// .panel-member .member-infos h4 {
//   margin-bottom: 0px !important;
//   margin-top: 3px !important;
//   font-size: 16px !important;
//   white-space: nowrap !important;
//   text-overflow: ellipsis !important;
// }

// .text-center {
//   text-align: center;
// }

// .text-info {
//   color: var(--ion-color-info);
// }

// .text-danger {
//   color: var(--ion-color-danger);
// }

// .label {
//   display: inline;
//   padding: 0.2em 0.6em 0.3em;
//   font-size: 75%;
//   font-weight: bold;
//   line-height: 1;
//   color: #fff;
//   text-align: center;
//   white-space: nowrap;
//   vertical-align: baseline;
//   border-radius: 0.25em;
// }

// .panel-member .member-top .label-outline {
//   background-color: rgba(250, 250, 250, 0.7);
//   color: #656565;
// }

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

// ion-app>ion-split-pane>ion-menu>ion-header {
//   color: white;
// }

ion-menu {
  --ion-background-color: var(--ion-color-primary);
  --ion-text-color: var(--ion-color-primary-contrast);

  ion-icon.menu-icon {
    color: white;
  }

  ion-icon.settings-icon {
    color: var(--ion-color-primary);
  }

  ion-toolbar {
    --background: var(--ion-color-primary);
  }

  ion-list {
    /* optional, but it needs when you use gradient as a background color.*/
    background: transparent;
  }
}

.popover-content.sc-ion-popover-ios {
  width: 80% !important;
}

.fcm-toast {
  .toast-button:before {
    font-family: 'Ionicons';
    margin-top: -14px;
    margin-left: -75px;
    z-index: 999;
    position: absolute;
    font-size: 2.2em;
    content: '\f366';
  }

  .toast-button {
    color: red;
  }
}

.fcm-toast .toast-button {
  color: red;
}

.no-network-mask {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100%;
  min-width: 100%;
  z-index: 3000;
}

.md ion-icon.rotate-90 {
  display: inline-block;
  transform: rotate(90deg);
}

// ion-item.priority {

//   border: 1px solid #409588;

//   div.item-native {
//     background-color: rgba(64, 149, 136, 0.1) !important;
//   }
// }

fa-icon {
  &.approved {
    color: var(--ion-color-success);
  }
  &.rejected {
    color: var(--ion-color-danger);
  }
  &.pending {
    color: var(--ion-color-warning);
  }
}

ion-list.settings-menu {
  ion-list-header {
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: var(--ion-color-secondary);
  }
  .item-label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }

  fa-icon {
    font-size: 24px;
    color: var(--ion-color-primary);
    width: 28px;
    text-align: center;
  }

  ion-item {
    --border-color: rgba(126, 99, 211, 0.09);
  }
  ion-label {
    //margin-left: 10px;
    font-size: 14px !important;
  }

  .ios ion-toggle {
    zoom: 0.8;
  }
}

.verification-rejected {
  display: block;
  padding-left: 40px;
  color: var(--ion-color-danger);
}
// .header-background {
//   left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     position: absolute;
//     -webkit-backdrop-filter: none;
//     backdrop-filter: none;
// }

.footer-md::before {
  background-image: none;
}
ion-content {
  --ion-background-color: var(--ion-color-light);
  background-color: var(--ion-color-light);
  --padding-top: 10px;

  &.full {
    --padding-top: 0px;
  }
}

.tripbackground {
  // margin-top: -20%;
  display: block;
  height: auto;
  width: 100%;
  &.pending {
    filter: grayscale(100%);
  }

  &.rejected {
    filter: grayscale(100%);
  }
}

.html-attribution {
  position: absolute;
  text-align: right;
  color: #7d7979;
  bottom: 10px;
  right: 10px;
  //padding-right: 36px;
  font-size: x-small;
  a {
    color: white;
    opacity: 0.4;
  }
}

ion-button.xs {
  font-size: 12px;
}

.login_wrapper,
.register_wrapper {
  min-height: 100%;
  background: #e662ac;
  background-image: url('/assets/img/v2/bg-logo.svg'); /* fallback */
  background-image: url('/assets/img/v2/bg-logo.svg'), linear-gradient(to top, #7d62d3, #e662ac);
  background-repeat: no-repeat;
  position: relative;
  padding: 25px;
  background-position-x: center;
  background-position-y: top;
  background-size: contain;
}

div.auth-button {
  border-radius: 27.5px !important;
  box-shadow: 0 2px 11px 0 rgba(255, 255, 255, 0.25) !important;
  background: linear-gradient(to right, #68b2f5, #2bcfda) !important;
  margin-bottom: 10px !important;
  margin-top: 20px;
  border: 0 !important;
  /* text-transform: uppercase !important; */
  outline: none !important;
  font-size: 16px;
  height: 50px;
  color: white;
  z-index: 5;
  display: flex;
  cursor: pointer;
  width: auto;
  &.noblock {
    min-width: 100px;
  }

  &.google {
    background: linear-gradient(to right, #dddddd, #ffffffe0) !important;
    color: #000000 !important;
  }
  &.apple {
    //     background: linear-gradient(to right, black, #2b2a2ae0) !important;
    background: linear-gradient(to right, #dddddd, #ffffffe0) !important;
    color: #000000 !important;
  }
  &.facebook {
    //  background: linear-gradient(to right, #4167b2, #4167b2e0) !important;
    background: linear-gradient(to right, #dddddd, #ffffffe0) !important;
    color: #4167b2 !important;
  }
  &.register {
    background-color: var(--ion-color-secondary) !important;
    // border:1px solid white !important;
    // box-shadow: none !important;
    background: var(--ion-color-secondary) !important;
  }
  &.email {
    background-color: var(--ion-color-primary) !important;
    background: var(--ion-color-primary) !important;
  }

  &.disabled {
    opacity: 0.7;
  }

  ion-icon {
    float: left;
    margin: auto 0;
    padding-left: 15px;
    font-size: 26px;
    display: inline;
  }
  fa-icon {
    float: left;
    /* margin-left: 10px; */
    margin: auto 0;
    padding-left: 15px;
    font-size: 20px;
    display: inline;
  }
  span {
    display: inline-block;
    /* border: 1px solid; */
    width: 80%;
    text-align: center;
    //padding-left: 10px;
    // padding-top: 2px;
    margin: auto 0;
    font-weight: bold;
    white-space: nowrap;
  }
}

.validator-error {
  font-size: small;
  margin-top: 2px;
  color: #902e2e;
  text-align: left;
}

strong.primary {
  color: var(--ion-color-primary) !important;
}
span.pill-primary {
  background-color: var(--ion-color-primary);
  color: white;
  border-radius: 4px;
  padding: 1px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
}
h4.primary {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.6px;
  /* text-transform: uppercase; */
  color: var(--ion-color-primary);
  margin-bottom: -5px;
}

ion-alert.promote-dialog {
  .alert-head {
    background-color: var(--ion-color-primary);
  }

  h2 {
    font-size: 20px !important;
    color: white !important;
    margin-top: 0px !important;
  }
  .alert-message {
    margin-top: 20px;
  }
}

ion-modal.disclaimer-dlg {
  &.bottom {
    align-items: flex-end;
  }

  .modal-wrapper {
    width: 75% !important;
    max-height: 60vh;
    overflow: auto;
    background-color: transparent;
  }
}

.online-status.big {
  display: inline-block;
  border-radius: 500px;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  background-color: transparent;
  margin: 0;
  margin-bottom: 0px;
  margin-left: 10px;
  &.online {
    background-color: var(--ion-color-success);
  }
}

div.no-result {
  color: darkgray;
  text-align: center;
  background-color: var(--ion-color-light);
  /* width: 100%; */
  display: block;
  margin: 10px;
  padding: 20px;
  border-radius: var(--twb-image-radius);

  a {
    color: darkgray;
    font-weight: bold;
  }
}

.modal-wrapper {
  background: transparent !important;
}

.all-starts {
  color: #fff;
  margin-top: 0;
  font-size: 17px;
  text-align: center;
  margin-bottom: 30px;
  padding-top: 10px;
  white-space: nowrap;
  font-weight: 700;
}

:focus {
  outline: none;
}

.subscription-info {
  font-size: small;
  color: var(--ion-color-light-contrast);
  margin-left: 20px;
  margin-right: 20px;
  a {
    color: var(--ion-color-light-contrast);
  }
}

.preview-card img {
  cursor: pointer;
}

.screen {
  width: 100%;
  ion-button.button-resize {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  ion-button.button-resize {
    max-width: 370px !important;
    margin: 5px auto;
  }
}

@media (min-width: 1200px) {
  .screen {
    width: 50% !important;
  }
}
