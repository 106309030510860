// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import "~swiper/swiper-bundle.css";

@import "theme/app.scss";


swiper-container {
    --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #3880ff);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  }
  
  swiper-slide {
    display: flex;
    position: relative;
  
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  
    width: 100%;
    height: 100%;
  
    font-size: 18px;
  
    text-align: center;
    box-sizing: border-box;
  }
  
  swiper-slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }