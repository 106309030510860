:root {

  --ion-font-family: "Arial", "Roboto", "Helvetica Neue", sans-serif;

  --ion-text-color: #4a4a4a;

  --ion-color-primary: #7e63d3;
  --ion-color-primary-rgb: 126,99,211;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #6f57ba;
  --ion-color-primary-tint: #8b73d7;

  --ion-color-secondary: #e062ae;
  --ion-color-secondary-rgb: 224,98,174;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #c55699;
  --ion-color-secondary-tint: #e372b6;

  --ion-color-tertiary: #68b2f5;
  --ion-color-tertiary-rgb: 104,178,245;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #5c9dd8;
  --ion-color-tertiary-tint: #77baf6;

  --ion-color-success: #28a745;
  --ion-color-success-rgb: 126,211,33;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #6fba1d;
  --ion-color-success-tint: #8bd737;

  --ion-color-warning: #f5a623;
  --ion-color-warning-rgb: 245,166,35;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d8921f;
  --ion-color-warning-tint: #f6af39;

  --ion-color-danger: #dc3545;
  --ion-color-danger-rgb: 255,78,78;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #e04545;
  --ion-color-danger-tint: #ff6060;

  --ion-color-dark: #4a4a4a;
  --ion-color-dark-rgb: 74,74,74;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #414141;
  --ion-color-dark-tint: #5c5c5c;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #989aa2;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --twb-image-radius: 8px;

    --ion-color-fabtransp: #7e63d390;
    --ion-color-fabtransp-rgb: 126,99,211,40;
    --ion-color-fabtransp-contrast: #ffffff;
    --ion-color-fabtransp-contrast-rgb: 255,255,255;
    --ion-color-fabtransp-shade: #6f57ba90;
    --ion-color-fabtransp-tint: #8b73d790;
  
  
//  --ion-safe-area-top: 20px !important;

}

.ion-color-fabtransp {
  --ion-color-base: var(--ion-color-fabtransp);
  --ion-color-base-rgb: var(--ion-color-fabtransp-rgb);
  --ion-color-contrast: var(--ion-color-fabtransp-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fabtransp-contrast-rgb);
  --ion-color-shade: var(--ion-color-fabtransp-shade);
  --ion-color-tint: var(--ion-color-fabtransp-tint);
}